<template>
  <div id="changPass" class="emissions-page padding-left-layout padding-top-layout">
    <page-title :title="textTitle" />
    <div class="page-header">{{ $t("change_pass_admin.description_enter_new_password") }}</div>

    <div class="main">
      <div class="wrap mt-48">
        <v-form @submit.prevent="submit" class="frm_changePass w-pass" ref="form" lazy-validation>
          <div class="input-block boxTop">
            <span class="input-title w-pass">{{ $t("change_pass_admin.label_current_password") }}</span>
            <div class="passBox w-pass">
              <input
                id="current_password"
                v-model="current_password"
                class="input-elic changePass-input w-pass"
                :type="!showPassCurrent ? 'password' : 'text'"
              />
              <eye-icon
                :id="'current_password_eye'"
                :changeIcon="() => (this.showPassCurrent = !this.showPassCurrent)"
              />
              <span class="err-mess" style="display: none" id="current_password_error"></span>
              <img
                id="current_password_img"
                class="validWarning"
                src="/img/icons/warning.svg"
                style="position: absolute; right: 6px; display: none"
              />
            </div>
          </div>
          <div class="input-block mt-40 w-pass">
            <span class="input-title w-pass">{{ $t("change_pass_admin.label_new_passowd") }}</span>
            <div class="passBox w-pass">
              <input
                id="password"
                v-model="password"
                class="input-elic changePass-input w-pass"
                :type="!showPassword ? 'password' : 'text'"
              />
              <eye-icon :id="'password_eye'" :changeIcon="() => (this.showPassword = !this.showPassword)" />
              <span class="err-mess" style="display: none" id="password_error"></span>
              <img
                id="password_img"
                class="validWarning"
                src="/img/icons/warning.svg"
                style="position: absolute; right: 6px; display: none"
              />
            </div>
          </div>
          <div class="input-block mt-40 w-pass">
            <span class="input-title w-pass">{{ $t("change_pass_admin.label_new_password_confirm") }}</span>
            <div class="passBox w-pass">
              <input
                id="password_confirmation"
                v-model="password_confirmation"
                class="input-elic changePass-input w-pass"
                :type="!showPassConfirm ? 'password' : 'text'"
              />
              <eye-icon
                :id="'password_confirmation_eye'"
                :changeIcon="() => (this.showPassConfirm = !this.showPassConfirm)"
              />
              <span class="err-mess" style="display: none" id="password_confirmation_error"></span>
              <img
                id="password_confirmation_img"
                class="validWarning"
                src="/img/icons/warning.svg"
                style="position: absolute; right: 6px; display: none"
              />
            </div>
          </div>

          <div class="instruction mt-40">
            <ul class="changePass-input changePass-text">
              {{ $t("change_pass_admin.description_condition_password") }}
              <li>{{ $t("change_pass_admin.description_number_charactor") }}</li>
              <li>{{ $t("change_pass_admin.description_rule_password") }}</li>
            </ul>
          </div>

          <input class="btn-submit mt-48 changePass-input" type="submit" :value="buttonChangPass" />
        </v-form>
      </div>
      <error-popup :dialog="showError" :message="message" @submit="handleClose" />
      <notification-popup :dialog="showSuccess" :message="messageSuccess" @submit="handleSuccess" />
      <dialog-popup
        :dialog="showConfirm"
        :message="messageConfirm"
        isShowCancelBtn
        :confirm="true"
        :confirmText="buttonConfirm"
        @close="showConfirm = false"
        @submit="handleOk"
        :closeText="closeText"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

import { changePasswordApi, checkPassword } from '@/api/auth';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import validate from '@/utils/validate';
import { ROUTES } from '@/router/constants';
import { validateRequired } from '@/api/others';
import EyeIcon from '@/components/commonApp/EyeIcon.vue';
import DialogPopup from '@/components/dialogs/question-popup.vue';
import notificationPopup from '@/components/dialogs/notification-popup.vue';
import PageTitle from '@/components/pageTitle/pageTitle.vue';
import { getErrorMessge } from '@/utils/messageHandle';

export default {
  name: 'ChangePasswordAdmin',
  components: {
    ErrorPopup,
    EyeIcon,
    DialogPopup,
    notificationPopup,
    PageTitle,
  },
  data() {
    return {
      items: [
        {
          text: this.$t("change_pass_admin.hyperlink_home"),
          disabled: false,
          href: ROUTES.HOME_DASHBOARD,
        },
        {
          text: this.$t("change_pass_admin.hyperlink_setting"),
          disabled: false,
          href: ROUTES.SETTING,
        },
        {
          text: this.$t("change_pass_admin.label_change_password"),
          disabled: true,
          href: ROUTES.FACILITY_MANAGEMENT,
        },
      ],
      current_password: '',
      showPassCurrent: false,
      password: '',
      showPassword: false,
      password_confirmation: '',
      showPassConfirm: false,
      passRules: [(v) => !!v, (value) => value && value.length >= 8, (v) => validate.regexPassword.test(v)],
      confirmPasswordRules: [(value) => !!value, (value) => value && value === this.password],
      showConfirm: false,
      showError: false,
      showSuccess: false,
      message: '',
      match: false,
      errorLst: [],
    };
  },
  computed: {
    textTitle() {
      return this.$t("change_pass_admin.title_change_password")
    },
    buttonChangPass() {
      return this.$t("change_pass_admin.button_change_password")
    },
    messageConfirm() {
      return this.$t("change_pass_admin.description_confirm_change_pass")
    },
    messageSuccess() {
      return this.$t("change_pass_admin.description_change_pass_success")
    },
    buttonConfirm() {
      return this.$t("change_pass_admin.button_change")
    },
    closeText() {
      return this.$t('popup.button_cancel')
    }
  },
  mounted() {
    this.updateBreadCrumb(this.items);
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        this.showError = false;
        this.showSuccess = false;
        this.showConfirm = false;
      }
    });
  },

  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),

    submit() {
      if (!validateRequired(['password_confirmation', 'password', 'current_password'])) {
        return;
      }
      if (
        this.current_password === this.password ||
        this.password != this.password_confirmation ||
        this.password.length > 20 ||
        !validate.regexPassword.test(this.password)
      ) {
        this.message = this.$t("change_pass_admin.description_password_incorrect");
        this.showError = true;
      } else {
        checkPassword({ current_password: this.current_password.trim() })
          .then(() => {
            this.showConfirm = true;
          })
          .catch((err) => {
            this.message = err.message;
            this.showError = true;
          });
      }
    },
    handleCancel() {
      this.showError = false;
    },
    handleOk() {
      changePasswordApi({
        current_password: this.current_password.trim(),
        password: this.password.trim(),
        password_confirmation: this.password_confirmation.trim(),
      })
        .then(() => {
          this.showConfirm = false;
          this.showSuccess = true;
        })
        .catch((err) => {
          this.showConfirm = false;
          this.showError = true;
          this.message = getErrorMessge(err);
          this.match = false;
        });
    },
    handleTogglePasswordCurrent() {
      this.showPassCurrent = !this.showPassCurrent;
    },
    handleTogglePassword() {
      this.showPassword = !this.showPassword;
    },
    handleTogglePasswordConfirm() {
      this.showPassConfirm = !this.showPassConfirm;
    },
    handleClose() {
      this.showConfirm = false;
      this.showError = false;
    },
    handleSuccess() {
      this.$router.push({ path: ROUTES.SETTING });
    },
  },
  beforeDestroy() {
    document.removeEventListener('keyup', () => {});
  },
};
</script>

<style scoped lang="scss">
@import './LoginStyle/index.scss';
.main {
  height: auto;
  flex-direction: column;
  overflow: hidden;
  min-height: 640px;
  .wrap {
    justify-content: center;
    width: 100%;
    flex-direction: row;
    overflow: hidden;
  }
  .contentBox {
    // width: 600px;
    .passBox {
      width: 100%;
    }
    input {
      width: 100%;
    }
  }
  .boxTop {
    margin-top: 80px;
  }
}
.title-text-style {
  font-size: 22px !important;
}
.page-header {
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.05em;
}
.instruction {
  ul {
    line-height: 24px;
    letter-spacing: 0.05em;
  }
}
.changePass-input {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.03em;
  &.changePass-text {
    margin: 0 auto;
    width: 100%;
  }
  &.btn-submit {
    margin: 0 auto;
    margin-top: 48px;
    width: 100%;
  }
}
.content-main {
  min-height: 50vh;
}

@media only screen and (max-width: 600px) {
  .w-pass {
    width: 100%;
  }
}
</style>
